import { useEventHandler } from "./useEventHandler";
import { useVisibleEffect } from "./useVisibilityChange";
export const useInterval = (interval, cb)=>{
    const fn = useEventHandler(cb);
    useVisibleEffect(()=>intervalEffect(interval, fn));
};
export function intervalEffect(interval, fn) {
    const start = Date.now();
    const handler = setInterval(()=>fn(Date.now() - start), interval);
    return ()=>clearInterval(handler);
}
